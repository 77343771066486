<div>
<h1 *ngIf="data && data.headline">{{data.headline}}</h1>
<p *ngIf="data && data.body">{{data.body}}</p>
<div>
  <button *ngIf="data && data.actionLabel" (click)="onAction()">
    {{data.actionLabel}}
  </button>
  <button *ngIf="data && data.cancelLabel" (click)="onCancel()">
    {{data.cancelLabel}}
  </button>
</div>
</div>
<mat-spinner *ngIf="data && data.spinner"></mat-spinner>
