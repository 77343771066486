export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDRngfdtVSadwpZQv1AO04QmhnGtiSKf3k',
    authDomain: 'gael-de-kock-estates.firebaseapp.com',
    databaseURL: 'https://gael-de-kock-estates.firebaseio.com',
    projectId: 'gael-de-kock-estates',
    storageBucket: 'gael-de-kock-estates.appspot.com',
    messagingSenderId: '1055368832625',
    appId: '1:1055368832625:web:0020064b9d540ad873ddd0',
    measurementId: 'G-4H88H8CE40'
  },
  googleMapsConfig: {
    apiKey: 'AIzaSyA0yp9KEqJOVxdzkcX9tgOIfQAKIHCWXak',
    libraries: ['places']
  }
};
