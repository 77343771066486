<div class="auth-wrapper">
  <div class="auth-wrapper__login-signup">
    <form class="form"
          [formGroup]="signupForm">
      <h2>Signup</h2>

      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput
               formControlName="name">
        <mat-error>Name Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Surname</mat-label>
        <input type="text"
               matInput
               formControlName="surname">
        <mat-error>Surname Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input type="email"
               matInput
               formControlName="email">
      </mat-form-field>

      <div formGroupName="matchingPasswords" class="signup-password">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input type="password"
                 matInput
                 formControlName="password" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input type="password"
                 matInput
                 formControlName="confirmPassword">
        </mat-form-field>
      </div>

      <button type="submit"
              *ngIf="!(isLoading$ | async)"
              class="gcr-btn-primary"
              (click)="onSubmit()">
        Create Account
      </button>
      <mat-spinner [diameter]="55" *ngIf="(isLoading$ | async)"></mat-spinner>
    </form>
  </div>
</div>


























<!--<div class="auth-wrapper">-->
  <!--<div class="login-signup" [ngClass]="{'valid-form' : signupForm.valid, 'error-form' : hasAuthError}">-->
    <!--<h2>-->
      <!--Sign Up-->
    <!--</h2>-->
    <!--<div class="form-error-message" *ngIf="hasAuthError">-->
      <!--<h4>-->
        <!--{{errorMessage}}-->
      <!--</h4>-->
      <!--&lt;!&ndash;<p>&ndash;&gt;-->
      <!--&lt;!&ndash;Did you forget your password?&ndash;&gt;-->
      <!--&lt;!&ndash;</p>&ndash;&gt;-->
    <!--</div>-->
   <!---->
    <!--<div class="signup-login-link">-->
      <!--<div class="signup-login-link__action">-->
        <!--<h4>-->
          <!--Already have an account?-->
        <!--</h4>-->
        <!--<a class="qss-button&#45;&#45;text-only" routerLink="/login">-->
          <!--Log In-->
        <!--</a>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
