export const svgList = [
  'heart--s',
  'bed--s',
  'bath--s',
  'email--s',
  'speech--s',
  'link--s',
  'success--s',
  'knysna--l',
  'note--s',
  'sent-mail--m',
  'social-whatsapp',
  'floor-size--s',
  'plot-size--s',
  '24-hour--s',
  'alarm-clock--s',
  'assign-user--s',
  'alcohol--s',
  'angle-down--s',
  'angle-left--s',
  'angle-right--s',
  'angle-up--s',
  'gc-realty',
  'gc-realty-mark',
  'arrow-down--s',
  'arrow-left--s',
  'arrow-right--s',
  'arrow-up--s',
  'barcode--s',
  'barcode-line--s',
  'basket--s',
  'calendar--s',
  'calendar-check--s',
  'car--s',
  'caret-down-f--s',
  'caret-left-f--s',
  'caret-right-f--s',
  'caret-up-f--s',
  'cart--s',
  'cart-empty--s',
  'check--s',
  'check-circle--s',
  'checkbox--s',
  'checkbox-checked--s',
  'clipboard--s',
  'clipboard-checkmark--s',
  'close--s',
  'close-circle--s',
  'close-circle-f--s',
  'coffee--s',
  'conversation--s',
  'coupon-savings--s',
  'credit-card--s',
  'dashboard--s',
  'download-arrow--s',
  'drag-drop-dot--s',
  'drug--s',
  'drug-contract--s',
  'directions-sign-f--s',
  'dollar-symbol--s',
  'duplicate--s',
  'envelope--s',
  'exclude--s',
  'error--s',
  'external-link--s',
  'extracare-card--s',
  'file-csv--s',
  'file-pdf--s',
  'file-check--s',
  'file-rates--s',
  'file-search--s',
  'file-send--s',
  'file-warn--s',
  'filter--s',
  'filter-f--s',
  'floor--s',
  'folder--s',
  'formularies--s',
  'food--s',
  'gear--s',
  'geo--s',
  'grapefruit--s',
  'hint-circle--s',
  'home--s',
  'hourglass--s',
  'close-btn--s',
  'factory--s',
  'handle--s',
  'hint-circle--s',
  'hourglass--s',
  'info-circle--s',
  'injection--s',
  'laptop--s',
  'lock--s',
  'mailbox--s',
  'map-bubble-f--s',
  'map-pin--s',
  'map-pin-check--s',
  'match--s',
  'menu--s',
  'minus--s',
  'modulator--s',
  'module--s',
  'more-horz-f--s',
  'more-vert-f--s',
  'mouse--s',
  'number-spin',
  'override--s',
  'parking--s',
  'garage--s',
  'lounge--s',
  'office--s',
  'dining-room--s',
  'game-room--s',
  'parking-bay--s',
  'pencil--s',
  'person-running--s',
  'phone--s',
  'photo--s',
  'photo-na--s',
  'plot--s',
  'plus--s',
  'print--s',
  'profile--s',
  'rates--s',
  'refresh--s',
  'reorder-simple--s',
  'research-results--s',
  'retail-store--s',
  'rx-bottle--s',
  'rx-bottle-label--s',
  'rx-bottle-transfer--s',
  'rx-bottle-refill--s',
  'rx-bottle-pills--s',
  'search--s',
  'share--s',
  'smartphone--s',
  'smoking--s',
  'sort--s',
  'spinner--s',
  'star--s',
  'star-circle--s',
  'star-f--s',
  'star-half-f--s',
  'stethoscope--s',
  'table--s',
  'tag--s',
  'target-f--s',
  'trash--s',
  'upload-arrow--s',
  'warning-circle--s',
  'weekly-ad--s',
  'mat-icon-ballot--s',
  'mat-icon-cloud-download--s',
  'mat-icon-dashboard--s',
  'mat-icon-drug--s',
  'mat-icon-invoices--s',
  'mat-icon-profile--s',
];
