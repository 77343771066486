import {Component, HostBinding, Inject, OnInit, Renderer2} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {RegisterIconsService} from './shared/icons/register-icons.service';
import {AuthService} from './auth/auth.service';
import {MatSelectChange} from '@angular/material/select';
import {DOCUMENT} from '@angular/common';
import {OverlayContainer} from '@angular/cdk/overlay';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnInit {
  readonly themeAnchor = this.document.getElementById('app-theme');
  // Old Method
  // @HostBinding('class')
  // currentTheme: 'light-theme' | 'dark-theme' = 'dark-theme';

  constructor(private afs: AngularFirestore,
              private authService: AuthService,
              @Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2,
              private overlayContainer: OverlayContainer,
              private registerIcons: RegisterIconsService) {
    this.authService.initAuthListener();
  }

  ngOnInit() {
    // Old Method

    // this.overlayContainer.getContainerElement().classList.remove('dark-theme');
    // this.overlayContainer.getContainerElement().classList.add('light-theme');
    // this.currentTheme = 'light-theme';


    // this.renderer.removeClass(this.document.body, 'dark-theme');
    // this.renderer.addClass(this.document.body, 'light-theme');
  }

  setTheme({ source }: MatSelectChange) {
    // console.log(source.value);
    if (source.value === 'light') {

      // Old Method

      // this.overlayContainer
      //   .getContainerElement()
      //   .classList.remove('dark-theme');
      //
      // this.overlayContainer
      //   .getContainerElement()
      //   .classList.add('light-theme');
      // this.currentTheme = 'light-theme';

      // this.renderer.removeClass(this.document.body, 'dark-theme');
      // this.renderer.addClass(this.document.body, 'light-theme');

      this.renderer.setAttribute(this.themeAnchor, 'href', '/light-theme.css');
    } else {
      this.renderer.setAttribute(this.themeAnchor, 'href', '/dark-theme.css');

      // Old Method

      // this.overlayContainer
      //   .getContainerElement()
      //   .classList.remove('light-theme');
      //
      // this.overlayContainer
      //   .getContainerElement()
      //   .classList.add('dark-theme');
      // this.currentTheme = 'dark-theme';

      this.renderer.removeClass(this.document.body, 'light-theme');
      this.renderer.addClass(this.document.body, 'dark-theme');
    }
  }
}
