<div class="toggle">
  <div class="toggle-message" *ngIf="title && title.length">{{title}}</div>
       <!--TODO: both 'color-deactivate': !toggled, and  'color-deactivate' : switchDisabled was here need change to an or is this correct?  -->
  <button
      id="toggle-btn"
      [disabled]="disabled"
      class="toggle-switch"
      (click)="toggleSwitch()"
      [ngClass]="{
                 'color-activate': value,
                 'color-deactivate': !value || disabled,
                 'toggle-switch--disabled' : disabled
                 }">
    <div id="toggle-slider" class="toggle-slider" [ngClass]="[value ? 'toggle-on' : 'toggle-off']">

      <div class="icon white-icon">

      </div>
      <mat-icon id="toggle-icon"
           class="icon"
           svgIcon="{{iconName}}"
           [ngClass]="[disabled ? 'grey-icon' : 'none']">
      </mat-icon>
    </div>
  </button>
</div>
