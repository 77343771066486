import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {convertToBoolean} from 'ng-boolean-input';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'gcr-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GCRToggleSwitchComponent),
      multi: true
    }
  ]
})
export class GCRToggleSwitchComponent implements ControlValueAccessor {
  @Input() title: string;
  @Input('value')


  @Input()
  set disabled(isDisabled) {
    this._disabled = convertToBoolean(isDisabled);
  }
  @Output() onToggle: EventEmitter<any> = new EventEmitter();
  private _disabled: boolean;
  private _val: boolean;

  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._val;
  }

  set value(val) {
    this._val = val;
    this.onChange(val);
    this.onTouched();
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }

  // We implement this method to keep a reference to the onTouched
  // callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // This is a basic setter that the forms API is going to use
  writeValue(value) {
      this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  get disabled(): boolean { return this._disabled; }

  get iconName(): string {
    return this.value ? 'check-circle--s' : 'close-btn--s';
  }

  toggleSwitch() {
      this.value = !this.value;
      this.onToggle.emit(this.value);
  }
}


