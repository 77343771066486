
<mat-toolbar color="primary" class="header" [ngClass]="getToolbarClass()">

    <div class="header__nav">
      <button class="logo gc-logo" (click)="navigate('listings')"><mat-icon svgIcon="gc-realty"></mat-icon> </button>

      <ul class="header__nav-list">

        <a class="nav-bar__menu__link"
           routerLinkActive="router-active"
           [routerLinkActiveOptions]="{exact:true}"
           routerLink="/listings/create"
           *ngIf="isAuth$ && authService.user$ | async as user">Create listing</a>

        <a class="nav-bar__menu__link"
           routerLinkActive="router-active"
           [routerLinkActiveOptions]="{exact:true}"
           routerLink="/listings"
            *ngIf="isAuth$ && authService.user$ | async as user">View listings</a>

        <a class="nav-bar__menu__link"
           routerLinkActive="router-active"
           routerLink="listings/manage"
           *ngIf="isAuth$ && authService.user$ | async as user">Manage listings</a>

        <a class="nav-bar__menu__link"
           (click)="logout()" *ngIf="isAuth$ && authService.user$ | async as user">Logout</a>

        <a class="nav-bar__menu__link"
           routerLinkActive="router-active"
           routerLink="login" *ngIf="!(isAuth$ | async)">Login</a>

        <!--      <ul *ngIf="isAuth$ && authService.user$ | async as user"-->
        <!--          (click)="toggleAccountMenu()"-->
        <!--          class="account-menu"-->
        <!--          qssDropdown-->
        <!--          routerLinkActive="router-active"-->
        <!--          [ngClass]="{'closed' : isAccountMenu , 'activated-route' : routeActivated}">-->
        <!--        <li class="account-menu__profile">-->
        <!--          &lt;!&ndash;<div class="avatar"></div>&ndash;&gt;-->
        <!--          <a>-->
        <!--            {{user.name}}-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li class="account-menu__items">-->
        <!--          <ul>-->
        <!--            <li>-->
        <!--              <a [routerLink]="['/hub']">-->
        <!--                Profile-->
        <!--              </a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a [routerLink]="['/hub', 'appointments']">-->
        <!--                Appointments-->
        <!--              </a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a [routerLink]="['/hub', 'medications']">-->
        <!--                Medications-->
        <!--              </a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a [routerLink]="['/hub', 'messages']">-->
        <!--                Messages-->
        <!--              </a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a [routerLink]="['/hub', 'admin-patients']">-->
        <!--                Patients-->
        <!--              </a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a [routerLink]="['/hub', 'admin-medications']">-->
        <!--                Medications-->
        <!--              </a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a [routerLink]="['/hub', 'admin-messages']">-->
        <!--                Messages-->
        <!--              </a>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <a (click)="onLogout()">-->
        <!--                Log Out-->
        <!--              </a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </li>-->
        <!--      </ul>-->

      </ul>
      <div class="mobile-menu-hide" (click)="menuOn()">
        <mat-icon svgIcon="menu--s" class="icon burger-icon"></mat-icon>
      </div>
    </div>

    <div *ngIf="isNavMenu"
         class="mobile-menu">
      <!--<div [@listState]="routeItems?.length">-->
      <div>

        <!--<a *ngFor="let routeItem of routeItems; let i = index"-->
        <!--[routerLink]="[routeItem.routerLink]"-->
        <!--(click)="menuOff()"-->
        <!--routerLinkActive="router-active">-->
        <!--{{routeItem.page}}-->
        <!--</a>-->

        <a [routerLink]="['/listings/create']"
           *ngIf="isAuth$ && authService.user$ | async as user"
           (click)="menuOff()"
           routerLinkActive="router-active">
          Create listing
        </a>
        <a [routerLink]="['/listings']"
           (click)="menuOff()"
           routerLinkActive="router-active">
          View listings
        </a>
        <a [routerLink]="['/listings/manage']"
           *ngIf="isAuth$ && authService.user$ | async as user"
           (click)="menuOff()"
           routerLinkActive="router-active">
          Manage listings
        </a>
        <a *ngIf="(isAuth$ | async)"
           (click)="logout()"
           routerLinkActive="router-active">
          Logout
        </a>
        <a *ngIf="!(isAuth$ | async)"
           (click)="menuOff(); navigate('login')"
           routerLinkActive="router-active">
          Log In
        </a>
      </div>
    </div>



</mat-toolbar>











