import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {DomPortalOutlet} from '@angular/cdk/portal';
import {GCRPortalComponentData} from './portal.interfaces';

@Injectable({
  providedIn: 'root'
})
export class GCRPortalService {

  private readonly componentFactoryResolver;
  private readonly injector;
  private readonly applicationRef;
  private portalOutlet;

  constructor(private compFactoryResolver: ComponentFactoryResolver,
              private inject: Injector,
              private appRef: ApplicationRef) {
    this.componentFactoryResolver = compFactoryResolver;
    this.injector = inject;
    this.applicationRef = appRef;
  }

  attach(portalOutlet, portal) {
    return portalOutlet.attach(portal);
  }

  projectContent(data: GCRPortalComponentData) {
    this.detach();
    this.portalOutlet = new DomPortalOutlet(
      document.querySelector(data.outletId),
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );
    return (this.portalOutlet && data.portal) ? this.attach(this.portalOutlet, data.portal) : null;
  }

  detach() {
    if (this.portalOutlet) {
      this.portalOutlet.detach();
    }
  }
}
