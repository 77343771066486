import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthService} from '../auth.service';
import {HeaderService} from '../../header/header.service';
import {UIService} from '../../shared/ui.service';
import {Observable, Subscription} from 'rxjs';
import 'rxjs/add/operator/map';
import {Store} from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import * as fromRoot from '../../app.reducer';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../auth.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  user: any;
  // isLoading = false;
  authSubscription: Subscription;
  hasAuthError = false;
  isLoading$: Observable<boolean>;
  isAuthing$: Observable<boolean>;
  errorMessage;
  private loadingSubs: Subscription;

  constructor(private afs: AngularFirestore,
              private afAuth: AngularFireAuth,
              private headerService: HeaderService,
              private uiService: UIService,
              private store: Store<{ui: fromApp.State}>,
              private authService: AuthService) { }

  ngOnInit(): void {


    // Console log for redux
    // this.store.subscribe(data => console.log(data));

    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.isAuthing$ = this.store.select(fromRoot.getIsAuth);

    // this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
    //   console.log(isLoading)
    //   this.isLoading = isLoading;
    // });

    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    });

    this.headerService.setLogo(true);
  }


  onLogin() {
    this.user = {
      email: this.loginForm.controls.email.value,
      // userId: Math.round(Math.random() * 10000).toString()
    };

    const loginData = {
      email: this.user.email,
      password: this.loginForm.controls.password.value
    };

    this.authService.login(loginData);

    this.authSubscription = this.authService.authErrorMessage.subscribe( authMessage => {
      this.errorMessage = authMessage;
    });

    this.authSubscription = this.authService.authError.subscribe( authStatus => {
      this.hasAuthError = authStatus;
      if (this.hasAuthError) {
        this.loginForm.get('password').reset();
      }
    });
  }

  // ngOnDestroy(): void {
  //   this.loadingSubs.unsubscribe();
  // }
}
