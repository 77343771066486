import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Observable, Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';
import {HeaderService} from './header.service';
import {animateMobileTrigger, listStateTrigger} from '../shared/animations';
import {Store} from "@ngrx/store";
import * as fromRoot from '../app.reducer';
import {ListingService} from "../listing/listing-service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    animateMobileTrigger,
    listStateTrigger
  ]
})
export class HeaderComponent implements OnInit {

  authSubscription: Subscription;
  user: Observable<any>;
  isAuth$: Observable<boolean>;
  user$: Observable<any>;
  showLogo = false;
  isNavMenu = false;
  isAccountMenu = false;
  isBrowser: boolean;
  routeActivated = false;

  constructor(public authService: AuthService,
              private store: Store<fromRoot.State>,
              private router: Router,
              private listingService: ListingService,
              private headerService: HeaderService) { }

  ngOnInit(): void {
    // this.isAuth$ = this.store.select(fromRoot.getIsAuth);

    // this.authSubscription = this.authService.authChange.subscribe( authStatus => {
    //   this.isAuth$ = authStatus;
    // });

    this.isAuth$ = this.store.select(fromRoot.getIsAuth);

    // this.authService.user$.pipe(first()).subscribe(user => {
    //   if (user) {
    //   }
    // });

  }


  logout() {
    this.menuOff();
    this.authService.logout();
    this.listingService.cancelSubscriptions();
    this.showLogo = false;
  }


  navigate(route) {
    // this.isNavMenu = false;
    // this.onShowLogo(route);
    this.router.navigate([`/${route}`]).then(result => {
    });
  }




  menuOn() {
    this.isNavMenu = !this.isNavMenu;
  }

  menuOff() {
    this.isNavMenu = false;
  }

  toggleAccountMenu() {
    this.isAccountMenu = !this.isAccountMenu;
  }

  onLogout() {
    // this._surgeryMessageService.cancelSubscriptions();
    // this._appointmentService.cancelSubscriptions();
    // this._medicationService.cancelSubscriptions();
    this.authService.logout();
  }

  getToolbarClass() {
    return {
      'open-mobile-menu': this.isNavMenu
    };
  }
}
