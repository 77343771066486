export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDyGajXTNRlw79I7dVoHCdgm3KFZ-00ayo',
    authDomain: 'gc-realty-stg.firebaseapp.com',
    projectId: 'gc-realty-stg',
    storageBucket: 'gc-realty-stg.appspot.com',
    messagingSenderId: '198152257596',
    appId: '1:198152257596:web:334818e61cffe525d10339'
  },
  googleMapsConfig: {
    apiKey: 'AIzaSyDyGajXTNRlw79I7dVoHCdgm3KFZ-00ayo',
    libraries: ['places']
  }
};
