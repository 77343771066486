import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GCRBannerComponentData, GCRBannerType} from "./banner.interface";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class GCRBannerComponent implements OnInit {

  // Could use the below HostBinding method but it is better to just target the component selector name
  // @HostBinding('class.app-banner')
  // hostClass = true;
  @Input() message = '';
  @Input()
  type: 'success' | 'info' | 'warn' | 'none' = 'none';

  @HostBinding('class')
  get HostClass() {
    if (this.type !== 'none') {
      return `app-banner-${this.type}`;
    }
  }

  hideX = false;
  isHorizontalBannerLinks = false;
  isBodyDetailsVisible = false;
  toggleMsg = 'Show More';

  @Input() data: GCRBannerComponentData;
  @HostBinding('class.alert-absolute-position') public absolute;

  get getToggleIcon() {
    return this.isBodyDetailsVisible ? 'angle-up--s' : 'angle-down--s';
  }


  constructor() { }

  ngOnInit(): void {
    this.absolute = this.data ? this.data.absolutePosition : null;
    if (this.data) {
      this.hideX = !!this.data.hideX ? this.data.hideX : this.hideX;
      this.isHorizontalBannerLinks = !!this.data.isHorizontalBannerLinks ? this.data.isHorizontalBannerLinks : this.isHorizontalBannerLinks;
    }
  }

  getClasses(): string {
    if (this.data.bannerType === GCRBannerType.Error) {
      return this.type = 'warn';
    } else if (this.data.bannerType === GCRBannerType.Success) {
      return this.type = 'success';
    } else if (this.data.bannerType === GCRBannerType.Warning) {
      return 'alert-warning';
    } else if (this.data.bannerType === GCRBannerType.Info) {
      return 'alert-info';
    }
  }

  getIconName() {
    if (this.data.bannerType === GCRBannerType.Error) {
      return 'error--s';
    } else if (this.data.bannerType === GCRBannerType.Success) {
      return 'check-circle--s';
    } else if (this.data.bannerType === GCRBannerType.Warning) {
      return 'warning-circle--s';
    } else if (this.data.bannerType === GCRBannerType.Info) {
      return 'info-circle--s';
    }
  }

  get getAriaRole(): string {
    return (this.data.bannerType === GCRBannerType.Error) ? 'alert' : 'status';
  }

  toggleMessage() {
    this.isBodyDetailsVisible = !this.isBodyDetailsVisible;
    if (this.isBodyDetailsVisible) {
      this.toggleMsg = 'Show Less';
    } else {
      this.toggleMsg = 'Show More';
    }
  }

}
