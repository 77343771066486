import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {svgList} from './constants';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RegisterIconsService {
  private svgList;

  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, @Inject(PLATFORM_ID) private platformId: string) {
    this.svgList = svgList;
    const domain = (isPlatformServer(platformId)) ? 'http://localhost:4200/' : '';
    svgList.map((svg) => {
      iconRegistry.addSvgIcon(svg, this.domSanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/svg/' + svg + '.svg'));
    });
  }
}

