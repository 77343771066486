import {Component, OnDestroy, OnInit} from '@angular/core';
import {dirtyCheck, DirtyComponent} from '@ngneat/dirty-check-forms';
import {Observable, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {store, store$} from './store';

@Component({
  selector: 'pg-settings',
  templateUrl: './dirty-check.component.html'
})
export class DirtyCheckComponent implements OnInit, DirtyComponent, OnDestroy {
  sub: Subscription;

  settings = new FormGroup({
    settingOne: new FormControl(null),
    settingTwo: new FormControl(null),
    settingThree: new FormControl(true)
  });

  isDirty$: Observable<boolean>;

  ngOnInit() {
    this.sub = store$.subscribe(state =>
      this.settings.patchValue(state, { emitEvent: false })
    );

    this.isDirty$ = dirtyCheck(this.settings, store$);
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  submit() {
    store.next(this.settings.value);
  }
}
