import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {UIService} from '../../shared/ui.service';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import * as fromRoot from '../../app.reducer';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../auth.component.scss']
})
export class SignUpComponent implements OnInit {

  signupForm: FormGroup;
  // isLoading = false;
  isLoading$: Observable<boolean>;
  isAuthing$: Observable<boolean>;
  private loadingSubs: Subscription;

  constructor(private authService: AuthService,
              private store: Store<{ui: fromApp.State}>,
              private uiService: UIService) { }

  ngOnInit(): void {

    // Console log for redux
    // this.store.subscribe(data => console.log(data));

    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.isAuthing$ = this.store.select(fromRoot.getIsAuth);

    // this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
    //   console.log(isLoading)
    //   this.isLoading = isLoading;
    // });

    this.signupForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required]
      }),
      surname: new FormControl('', {
        validators: [Validators.required]
      }),
      email: new FormControl('', {
        validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]
      }),

      matchingPasswords : new FormGroup({
        password: new FormControl('', Validators.compose([
          Validators.minLength(6),
          Validators.required
        ])),
        confirmPassword: new FormControl('', Validators.required)
      })
      //   (formGroup: FormGroup) => {
      //   return PasswordValidator.areEqual(formGroup);
      // }),
    });
  }

  onSubmit() {

    const capitalize = (s) => {
      if (typeof s !== 'string') { return ''; }
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const data = {
      name: capitalize(this.signupForm.get('name').value),
      surname: capitalize(this.signupForm.get('surname').value),
      email: this.signupForm.get('email').value,
      password: this.signupForm.get('matchingPasswords').get('password').value,
      confirmPassword: this.signupForm.get('matchingPasswords').get('confirmPassword').value,
    };

    this.authService.registerUser({
      email: this.signupForm.value.email,
      password: this.signupForm.get('matchingPasswords').value.password
    }, data);
  }

  // ngOnDestroy(): void {
  //   this.loadingSubs.unsubscribe();
  // }
}
