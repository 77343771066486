import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationDialogData} from "./confirmation.model";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
// @Output() onConfirmClick = new EventEmitter();
// @Output() onCancelClick = new EventEmitter();
  constructor(@Inject(MAT_DIALOG_DATA)
              public data:ConfirmationDialogData,
              private dialogRef: MatDialogRef<ConfirmationDialogData>) { }

  ngOnInit(): void {

  }
  onAction(){
    // this.onConfirmClick.emit(data)
    this.dialogRef.close('actionCalled')
  }

  onCancel(){
    // this.onConfirmClick.emit(data)
    this.dialogRef.close('cancelCalled')
  }
}
