import {NgModule} from '@angular/core';
import {GCRToggleSwitchComponent} from './toggle-switch.component';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';


@NgModule({
  declarations: [
    GCRToggleSwitchComponent
  ],
  imports: [
    MatIconModule,
    CommonModule,
    BrowserModule
  ],
  exports: [
    GCRToggleSwitchComponent
  ],
  entryComponents: [],
  providers: [],
})
export class GCRToggleSwitchModule {
}
