import {GCRPortalComponentData} from './portal/portal.interfaces';

export interface GCRBannerComponentData extends GCRPortalComponentData {
  /**
   * Alert headline text
   */
  headline?: string;
  /**
   * Changes color and use case. Options are Error, Info, Warning, and Success
   */
  bannerType: GCRBannerType;
  /**
   * flag to hide/show Close-X Button
   */
  hideX?: boolean;
  /**
   * flag to display banner links horizontally instead of vertically
   */
  isHorizontalBannerLinks?: boolean;
  /**
   * Body text of alert. Is optional.
   */
  body?: string;
  /**
   * Additional body text that can be toggled. Is only applicable for Info and Warning alerts. Is optional.
   */
  bodyDetails?: string;
  /**
   * Function called when alert is closed. Is optional.
   */
  closeCallBack?: () => void;
  /**
   * Set alert to be closed after a certain amount of time. Is optional.
   */
  removedAfterMilliseconds?: number;
  /**
   * Set position of alert to absolute. Is optional.
   */
  absolutePosition?: boolean;
  /**
   *  Collection of Banner Links
   */
  bannerLinks?: BannerLink[];
  /**
   *  Enables a link that allows the user to pass in a function intended to shift the focus to another element,
   *  The link will only be visible if it has the focus (if the element is tabbed to)
   */
  skipToLink?: BannerLink;
}

export interface BannerLink {
  /**
   * Text that can be clicked to call linkFunc().
   */
  linkText: string;

  /**
   * Function called when linkText is clicked
   */
  linkFunc(): void;
}

export enum GCRBannerType {
  /**
   * Types of Banner
   */
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
}
