import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';

const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      meta: {
        title: 'Login',
        description: ''
      }
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [ RouterModule]
})

export class AuthRoutingModule {
}

