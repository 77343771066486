import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  showLogoSubject = new Subject<boolean>()
  showLogo: boolean;

  constructor() { }

  setLogo(flag) {
    this.showLogo = flag;
    this.isLogo();
  }

  isLogo() {
    this.showLogoSubject.next(this.showLogo);
    return this.showLogo;
  }
}
