import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {simpleTrigger, statusTrigger} from '../animations';

@Component({
  selector: 'message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss'],
  animations: [
    simpleTrigger,
    // trigger('Pencil', [
    //   transition('* <=> *', [
    //     group([
    //       animate('300ms ease-in-out', keyframes([
    //         style({
    //           opacity: 0,
    //           offset: 0,
    //           transform: 'translateY(20px)',
    //         }),
    //         style({
    //           opacity: 1,
    //           offset: 1,
    //           transform: 'translateY(0px)',
    //         })
    //       ])),
    //     ]),
    //   ]),
    // ]),
    // trigger('sendState', [
    //   transition(':enter', [
    //     group([
    //       query('#EditMode', [
    //         style({
    //           transform: 'translateY(20px)',
    //           opacity: 1
    //         }),
    //         animate('300ms cubic-bezier(.83,-0.39,.26,1.38)')
    //       ], { optional: true }),
    //       query('#cancelMode', [
    //         animate('300ms cubic-bezier(.83,-0.39,.26,1.38)', keyframes([
    //           style({
    //             offset: 0,
    //             transform: 'translateY(-20px)',
    //           }),
    //           style({
    //             offset: 1,
    //             transform: 'translateY(0px)',
    //           })
    //         ])),
    //       ],  { optional: true })
    //     ])
    //   ]),
    //
    //   transition(':leave', [
    //     query('#EditMode', [
    //       animate('300ms cubic-bezier(.83,-0.39,.26,1.38)', keyframes([
    //         style({
    //           offset: 0,
    //           transform: 'translateY(0px)',
    //         }),
    //         style({
    //           offset: 1,
    //           transform: 'translateY(20px)',
    //         })
    //       ])),
    //     ], { optional: true }),
    //     query('#cancelMode', [
    //       animate('300ms cubic-bezier(.83,-0.39,.26,1.38)', keyframes([
    //         style({
    //           offset: 0,
    //           transform: 'translateY(0px)',
    //         }),
    //         style({
    //           offset: 1,
    //           transform: 'translateY(-20px)',
    //         })
    //       ])),
    //     ],  { optional: true })
    //   ])
    // ]),
  ]
})

export class MessageStatusComponent implements OnInit {
  sendMessage: boolean;
  @Input() headline = 'Message Sent!';
  @Input() body = 'We will get back to you as soon as possible.';
  @Input() iconName = 'sent-mail--m';
  @Input() countdown = 5000;
  @Input() countdownDelay = 6000;
  @Output() close = new EventEmitter();

  ngOnInit() {
    this.sendMessage = true;
    setTimeout(() => {
      this.sendMessage = false;
    }, this.countdown);

    setTimeout(() => {
      // this.sendMessage = false;
      this.onClose();
    }, this.countdownDelay);
  }

  // TODO Add ability to close manually

  // How to do onChanges

  // ngOnChanges(changes: SimpleChanges): void {
  //   const messageTriggered = changes['sendMessage'];
  //   if (messageTriggered.currentValue == true) {
  //     setTimeout(() => {
  //       this.sendMessage = false;
  //     },3000)
  //   }
  //   console.log(changes)
  // }

  // messageAnimation() {
  //   this.sendMessage = true;
  //   setTimeout(() => {
  //     // this.sendMessage = false;
  //   }, 4000);
  // }

  onClose() {
    this.close.emit(null);
  }
}
