<form [formGroup]="settings" nz-form class="container">

  <mat-form-field appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput
           formControlName="settingOne">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput
           formControlName="settingTwo">
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>Listing Status</mat-label>
    <mat-select formControlName="settingThree" disableOptionCentering>
      <mat-option label="Jack" value="jack"></mat-option>
      <mat-option label="Lucy" value="lucy"></mat-option>
      <mat-option label="Tom" value="tom"></mat-option>
    </mat-select>
  </mat-form-field>

  <button (click)="submit()"
    *ngIf="isDirty$ | async">
    Save
  </button>
</form>
