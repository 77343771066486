import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GcrSnackbarComponent} from '../shared/gcr-snackbar/gcr-snackbar.component';
import {UIService} from '../shared/ui.service';
import * as fromApp from '../app.reducer';
import {Store} from '@ngrx/store';
import * as Auth from './auth.actions';

@Injectable()
export class AuthService {
  user$: Observable<any>;
  private isAuthenticated = false;
  authChange = new Subject<boolean>();
  authError = new Subject<boolean>();
  authErrorMessage = new Subject<string>();

  constructor(private router: Router,
              private afAuth: AngularFireAuth,
              private snackBar: MatSnackBar,
              private uiService: UIService,
              private store: Store<{ui: fromApp.State}>,
              private afs: AngularFirestore) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  initAuthListener() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        // this.isAuthenticated = true;
        // this.authChange.next(true);
        // We now use our reducer
        // console.log('authed')
        this.store.dispatch(new Auth.SetAuthenticated());
        // this.router.navigate(['/listings']);
      } else {
        // this.authChange.next(false);
        // this.isAuthenticated = false;
        // console.log('un - authed')
        // We now use our reducer
        this.store.dispatch(new Auth.SetUnauthenticated());
        // this.router.navigate(['/']);
      }
    });
  }


  registerUser(authData: any, userData: any) {
    // this.uiService.loadingStateChanged.next(true);
    this.store.dispatch({ type: 'START_LOADING'});
    this.afAuth.createUserWithEmailAndPassword(
      authData.email,
      authData.password
    ).then(user => {
      // this.uiService.loadingStateChanged.next(false);
      this.store.dispatch({ type: 'STOP_LOADING'});
      this.SetUserData(user.user, userData);
    })
      .catch(error => {
        // this.uiService.loadingStateChanged.next(false);
        this.store.dispatch({ type: 'STOP_LOADING'});
        // console.log(error);
      });
  }


  SetUserData(user, userData) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: any = {
      userId: user.uid,
      email: user.email,
      name: userData.name,
      surname: userData.surname,
      photoURL: '',
    };
    return userRef.set(data);
  }

  login(loginData: any) {

    // this.uiService.loadingStateChanged.next(true);
    this.store.dispatch({ type: 'START_LOADING'});
    this.afAuth.signInWithEmailAndPassword(
      loginData.email,
      loginData.password
    ).then(user => {
      // this.uiService.loadingStateChanged.next(false);
      this.store.dispatch({ type: 'STOP_LOADING'});
      this.router.navigate(['/listings']);
      return this.loginUserData(user);
    })
      .catch(error => {
        // this.uiService.loadingStateChanged.next(false);
        this.store.dispatch({ type: 'STOP_LOADING'});
        // this.snackBar.openFromComponent(GcrSnackbarComponent, {
        //   duration: 5000,
        //   data: {message: 'Invalid Credentials'}
        // });
        // console.log(error);

        this.authFailed(error.message);
      });
  }

  loginUserData(user) {
    // this.authChange.next(true);
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.user.uid}`);
    const data: any = {
      uid: user.user.uid,
      email: user.user.email,
      // name: user.name,
      // surname: user.surname,
      // photoURL: user.photoURL,
    };
    // this.user$ = this.afAuth.authState;
    // this.initAuthListener();
    return userRef.set(data, {merge: true});
  }


  logout() {
    // this.user$ = null;
    // this.authChange.next(false);
    // this.afAuth.signOut();
    this.afAuth.signOut();
  }

  isAuth() {
    return this.user$ != null;
  }

  private authFailed(errorMessage: string) {
    this.authError.next(true);
    this.authErrorMessage.next(errorMessage);
  }

  canRead(user: any): boolean {
    const allowed = ['admin', 'patient'];
    return this.checkAuthorization(user, allowed);
  }

  private checkAuthorization(user: any, allowedRoles: string[]): boolean {
    if (!user) { return false; }
    for (const role of allowedRoles) {
      if ( user.roles[role] ) {
        return true;
      }
    }
    return false;
  }

}
