import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {ConfirmationDialogComponent} from "./confirmation-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule, MatSpinner} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent, MatDialogModule],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ]
})
export class ConfirmationDialogModule { }
