import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import * as UI from '../shared/ui.actions';

@Injectable()
export class ListingService {

  private _data: BehaviorSubject<any[]>;
  public data: Observable<any[]>;
  firstInResponse: any;
  latestEntry: any;
  listing;
  listingList = [];
  lid;
  listingSubject = new Subject<any>();
  listingIdSubject = new Subject<any>();
  emitMessage = new Subject<boolean>();
//Maintain the count of clicks on Next Prev button
  public pagination_clicked_count = 0;

  //Maintain the count of clicks on Next Prev button
  public pageSize = 20;

  private fbSubs: Subscription[] = [];

  constructor(private afs: AngularFirestore, private store: Store<{ui: fromRoot.State}>) {}

  // You need to return the doc to get the current cursor.
  getCollection(ref, queryFn?): Observable<any[]> {
    this.store.dispatch(new UI.StartLoading());
    return this.afs.collection(ref, queryFn).snapshotChanges().map(actions => {
      return actions.map(a => {
        // const data = a.payload.doc.data();
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        const doc = a.payload.doc;
        return {
          general: data.general,
          features: data.features,
          accessibility: data.accessibility,
          imageObject: data.imageObject,
          prices: data.prices,
          propertyDetails: data.propertyDetails,
          lid: data.lid,
          userData: data.userData,
          id,
          doc
        };
      });
    });
  }


// In your first query you subscribe to the collection and save the latest entry
  first() {
    this._data = new BehaviorSubject([]);
    this.data = this._data.asObservable();

    this.pagination_clicked_count = 0;
    // this.disable_next = false;
    // this.disable_prev = false;

    const scoresRef = this.getCollection('listings', ref => ref
      .orderBy('general.date', 'desc')
      .limit(5))
      .subscribe(data => {
        // this.firstInResponse = data[0].payload.doc;
        this.latestEntry = data[data.length - 1].doc;
        this._data.next(data);
        this.listingList.push(data);
        this.store.dispatch(new UI.StopLoading());
      });
    // this.store.dispatch(new UI.StopLoading());
  }

  next() {
    if (this.pagination_clicked_count < 1) {
    const scoresRef = this.getCollection('listings', ref => ref
      .orderBy('general.date', 'desc')
      // Now you can use the latestEntry to query with startAfter
      .startAfter(this.latestEntry)
      .limit(6))
      .subscribe(data => {
        if (data.length) {
          // And save it again for more queries
          this.latestEntry = data[data.length - 1].doc;
          this._data.next(this.listingList[0].concat(data));
          this.store.dispatch(new UI.StopLoading());
          this.pagination_clicked_count++;
        }
      });
    }
  }

  getListings(): Observable<any[]> {
    return this.afs
      .collection<any>('listings')
      .snapshotChanges()
      .pipe(
        map(actions => {
            return actions.map(action => {
              const data = action.payload.doc.data() as any;
              return {
                general: data.general,
                features: data.features,
                accessibility: data.accessibility,
                imageObject: data.imageObject,
                prices: data.prices,
                propertyDetails: data.propertyDetails,
                lid: data.lid,
                userData: data.userData,
              };
            });
          }
        ));
  }


  getListingDetail(lid): any {
    this.fbSubs.push(
      this.afs
        .collection<any>('listings')
        .doc(lid)
        .snapshotChanges()
        .pipe(
          map(action => {

              const data = action.payload.data() as any;
              return {
                general: data.general,
                features: data.features,
                accessibility: data.accessibility,
                imageObject: data.imageObject,
                prices: data.prices,
                propertyDetails: data.propertyDetails,
                private: data.private,
                lid: data.lid,
                userData: data.userData,
              };
            }
          )).subscribe((listing: any) => {
        this.listing = listing;
        this.listingSubject.next(this.listing);
      })
    );
  }

  createListings(data): Promise<void> {
    return this.afs
      .collection<any>('listings').add(
      data
    ).then((docRef) => {
        this.lid = docRef;
        this.afs.collection('listings')
          .doc(this.lid.id)
          .set(({
            lid: this.lid.id
          }), {merge: true}).then(x => {
          this.listingIdSubject.next(this.lid.id);
        });
      });
  }

  checkValidScript(lid) {
    this.afs.firestore.doc('/listings/brjZTOUfsBK64dIDQGtU').get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
        }
      });
  }



  updatePatientNote(data) {
    return this.afs.collection<any>('listings')
      .doc(data.lid)
      .set(({
        data
      }), {merge: true}).then(x => {
        this.listingSubject.next(this.listing);
      });
  }

  deleteListing(listing) {
    return this.afs.collection('listings')
      .doc(listing.lid)
      .delete();
  }

  onSendEmail(message: any) {
    const data = {
      name: message.name,
      phone: message.phone ? message.phone : null,
      email: message.email,
      emailDate: message.emailDate,
      messageTitle: message.messageTitle,
      messageType: message.messageType,
      message: message.message ? message.message : null,
      messageSubject: message.messageSubject,
      status: message.status,
      sendTo: message.sendTo,
      listingTitle: message.listingTitle,
      listingLocation: message.listingLocation,
      listingLid: message.listingLid,
    };

    this.emitMessage.next(true);
    this.afs
      .collection<any>('messages')
      .doc(`${data.messageType}`)
      .collection(`messages`)
      .add(data)
      .catch()
      .then(() => {
        this.emitMessage.next(true);
      });
  }

  cancelSubscriptions() {
    this.fbSubs.forEach(sub => sub.unsubscribe());
  }
}
