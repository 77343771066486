<!--<app-header></app-header>-->
<div class="content">
  <!--<mat-form-field appearance="standard">-->
    <!--<mat-label>Select Theme</mat-label>-->
    <!--<mat-select (selectionChange)="setTheme($event)">-->
      <!--<mat-option value="light">Light</mat-option>-->
      <!--<mat-option value="dark">Dark</mat-option>-->
    <!--</mat-select>-->
  <!--</mat-form-field>-->

  <router-outlet></router-outlet>
  <!--<app-footer></app-footer>-->
</div>
