import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ListingService} from '../listing-service';

@Injectable()
export class UploaderService {


  authError = new Subject<boolean>();
  authErrorMessage = new Subject<string>();
  user$: Observable<any>;

  files: File[] = [];
  lid: string;
  lidSubject = new Subject<string>();
  isUploadingSubject = new Subject<boolean>();
  isLoading = false;

  constructor(private afs: AngularFirestore, private listingService: ListingService) {
    // this.user$ = this.afAuth.authState.pipe(
    //   switchMap(user => {
    //     if (user) {
    //       return this.afs.doc<IUser>(`users/${user.uid}`).valueChanges();
    //     } else {
    //       return of(null);
    //     }
    //   })
    // );
    this.listingService.listingIdSubject.subscribe(x => {
      // console.log(x)
    });
  }


  uploadPhoto(photos) {
    // console.log(this.lid, '~~~~~~~~~~~~~~~~UPLOADPHOTO');
    // console.log(photoData, 'photoData on drop')
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`listings/tester`);
    // photoData.push({
    //     image: photoData.downloadURL,
    //     thumbImage: photoData.downloadURL,
    //     filename: photoData.name,
    //     size: photoData.size
    //   });
    // const data: any = {
    //     studyList: photoData
    //   };
    this.listingService.listingIdSubject.subscribe(x => {
      // console.log(x)
    });
    this.setLid(this.lid);
  }


  getLid(): string {
    return this.lid;
  }

  startUploading() {
    this.isLoading = true;
  }


  isUploading() {
    this.isUploadingSubject.next(true);
  }

  finishedUploading() {
    this.isUploadingSubject.next(false);
  }

  setLid(lid){
    this.lid = lid;
    this.lidSubject.next(this.lid);
    // console.log('set Lid', lid);
  }
}
