import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {GCRBannerComponent} from './banner.component';

@NgModule({
  declarations: [
    GCRBannerComponent
  ],
  imports: [
    MatIconModule,
    CommonModule,
    MatButtonModule
  ],
  providers: [],
  exports: [GCRBannerComponent],
  entryComponents: [GCRBannerComponent],
})
export class GCRBannerModule {
}
