import {Injectable, NgZone} from '@angular/core';
import {ComponentPortal, TemplatePortal} from '@angular/cdk/portal';
import {GCRBannerComponentData} from './banner.interface';
import {GCRBannerComponent} from './banner.component';
import {GCRPortalService} from './portal/portal.service';

@Injectable({
  providedIn: 'root'
})
export class GCRBannerService {

  private timeout;

  constructor(private portalService: GCRPortalService, private  ngZone: NgZone) {
  }

  setupTimeout(time) {
    this.ngZone.runOutsideAngular(() => {
      this.timeout = setTimeout(() => this.ngZone.run(() => this.close()), time);
    });
  }

  safelyClearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  sendAlert(data: GCRBannerComponentData) {
    if (data && data.template && data.viewContainerRef) {
      data.portal = new TemplatePortal(data.template, data.viewContainerRef);
    } else {
      data.portal = new ComponentPortal(GCRBannerComponent);
    }
    if (data.closeCallBack == null) {
      data.closeCallBack = () => this.close();
    }
    const componentRef = this.portalService.projectContent(data);
    if (componentRef && data && data.template == null) {
      componentRef.instance.data = data;
      componentRef.changeDetectorRef.detectChanges();
    }
    if (data.removedAfterMilliseconds) {
      this.setupTimeout(data.removedAfterMilliseconds);
    }
  }

  close() {
    this.safelyClearTimeout();
    this.portalService.detach();
  }
}
