<div class="gcr-light-box">
  <button class="gcr-light-box-icon gcr-light-box-icon--close"
          mat-dialog-close>
    <mat-icon svgIcon="close--s"></mat-icon>
  </button>

  <button class="gcr-light-box-icon gcr-light-box-icon--left"
          (click)="showPreviousImage()">
    <mat-icon svgIcon="angle-left--s"></mat-icon>
  </button>

  <ng-container *ngFor="let item of images; let i = index">
    <div class="gcr-light-box__image">
      <img *ngIf="i == counter"
           [src]="item">
    </div>
  </ng-container>

  <button class="gcr-light-box-icon gcr-light-box-icon--right"
          (click)="showNextImage()">
    <mat-icon svgIcon="angle-right--s"></mat-icon>
  </button>

</div>


