import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HeaderService} from '../header/header.service';
import {GCRBannerComponentData, GCRBannerType} from '../shared/banner/banner.interface';
import {GCRBannerService} from '../shared/banner/banner.service';
import {AuthService} from '../auth/auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  data: GCRBannerComponentData;
  error = GCRBannerType.Error;
  info = GCRBannerType.Info;
  success = GCRBannerType.Success;
  warning = GCRBannerType.Warning;


  constructor(private router: Router,
              public authService: AuthService,
              private headerService: HeaderService,
              private bannerService: GCRBannerService) { }

  ngOnInit(): void {
    this.headerService.setLogo(false);
  }

  sendAlert(alert: GCRBannerType) {
    this.data = {
      bannerType: alert,
      bodyDetails: 'This is some additional information for new portal banner.',
      headline: 'This is custom headline',
      body: 'This is custom body',
      outletId: '#placeholder1',
    };
    this.bannerService.sendAlert(this.data);
  }

  onExplore(){
    this.router.navigate(['listing']);
  }
}
