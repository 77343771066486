<div class="auth-wrapper">
  <div class="auth-wrapper__login-signup">
    <div class="auth-wrapper__login-signup__heading">
      <mat-icon svgIcon="gc-realty-mark" class="gc-logo-mark"></mat-icon>
      <h2>
        Account Login
      </h2>
    </div>
    <form class="auth-wrapper__login-signup__form" [formGroup]="loginForm">
    <mat-form-field appearance="outline">
      <mat-label>Email Address</mat-label>
      <input type="email"
             matInput
             #login="matInput"
             formControlName="email">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input type="password"
             matInput
             formControlName="password">
    </mat-form-field>
    <button type="submit"
            *ngIf="!(isLoading$ | async)"
            class="gcr-btn-primary"
            (click)="onLogin()">
      Log In
    </button>
    </form>
    <div class="form-error-message" *ngIf="hasAuthError">
      <h4>
        {{errorMessage}}
      </h4>
    </div>
    <mat-spinner [diameter]="55" *ngIf="(isLoading$ | async)"></mat-spinner>
  </div>
</div>
