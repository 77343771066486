<h4 class="heading">Note:</h4>
<p class="message">{{message}}</p>


<div class="alert-message" [ngClass]="getClasses()" tabindex="-1" role="banner" aria-live="polite"
     [attr.aria-label]="data.bannerType">
  <div class="alert-message__close-btn" *ngIf="!hideX">
    <button id="closeButton" mat-icon-button (click)="data.closeCallBack()">
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <mat-icon class="alert-message__icon" aria-hidden="true" [svgIcon]="getIconName()"></mat-icon>
  <h2 *ngIf="data.headline" class="alert-message__heading">
    {{data.headline}}
    <a *ngIf="data.skipToLink" tabindex="0" class="alert-message__skip-to-link"
       (keydown.enter)="data.skipToLink.linkFunc()">
      {{data.skipToLink.linkText}}
    </a>
  </h2>
  <div *ngIf="data.body" class="alert-message__body-text">
    {{data.body}}
  </div>

  <div *ngIf="data.bodyDetails && isBodyDetailsVisible" class="alert-message__body-details-text">
    {{data.bodyDetails}}
  </div>

  <div class="alert-message__horizontal-banner-links" *ngIf="isHorizontalBannerLinks">
    <span *ngFor="let link of data.bannerLinks; let i = index" id="horizontal-link-{{i}}">
      <a tabIndex="0" class="horizontal-links" (keydown.enter)="link.linkFunc()" (click)="link.linkFunc()">
      {{link.linkText}}
      </a>
    </span>
  </div>

  <div class="alert-message__vertical-banner-links" *ngIf="!isHorizontalBannerLinks">
    <span *ngFor="let link of data.bannerLinks; let i = index">
      <a tabIndex="0" id="vertical-link-{{i}}" class="vertical-links"
         (keydown.enter)="link.linkFunc()" (click)="link.linkFunc()">
     {{link.linkText}}
      </a>
    </span>
  </div>

  <div class="alert-message__body-text">
    <ng-content></ng-content>
  </div>

  <div *ngIf="data.bodyDetails" class="alert-message__toggle-section" (click)="toggleMessage()">
    <button id="toggle-button-id" mat-flat-button>
      {{toggleMsg}}
      <mat-icon id="toggle-icon-id" [svgIcon]="getToggleIcon"></mat-icon>
    </button>
  </div>
</div>
