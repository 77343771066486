<div class="input-number-step-wrapper">
  <label>{{counterLabel}}</label>
  <div class="input-number-step-container">
    <button class="input-number-step-button" (click)="decrease()">
      <mat-icon svgIcon="minus--s"></mat-icon>
    </button>

    <span class="input-number-step-number">{{counterValue}}</span>

    <button class="input-number-step-button" (click)="increase()">
      <mat-icon svgIcon="plus--s"></mat-icon>
    </button>
  </div>
</div>
