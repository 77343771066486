import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment.prod';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {HttpClientModule} from '@angular/common/http';
import {OverlayModule} from '@angular/cdk/overlay';
import {ClickOutsideDirective} from './shared/click-outside.directive';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FullDatePipe} from './shared/date.pipe';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';
import {GCRToggleSwitchModule} from './shared/toggle-switch/toggle-switch.module';
import {GCRCounterInputModule} from './shared/counter-input/counter-input.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {DropzoneDirective} from './shared/dropzone.directive';
import {UploaderService} from './listing/listing-create/uploader.service';
import {AuthModule} from './auth/auth.module';
import {AuthService} from './auth/auth.service';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {A11yModule} from '@angular/cdk/a11y';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { GcrSnackbarComponent } from './shared/gcr-snackbar/gcr-snackbar.component';
import {SharedMaterialModule} from './shared-material.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ConfirmationDialogModule} from './shared/confirmation-dialog/confirmation-dialog.module';
import {ConfirmationDialogComponent} from './shared/confirmation-dialog/confirmation-dialog.component';
import {AngularFireFunctionsModule, REGION} from '@angular/fire/functions';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {GCRBannerModule} from './shared/banner/banner.module';
import {UIService} from './shared/ui.service';
import {StoreModule} from '@ngrx/store';
import {LightboxComponent} from './shared/image-slider/lightbox/lightbox.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DirtyCheckComponent} from './shared/dirty-check/dirty-check.component';
import {MessageStatusModule} from './shared/message-status/message-status.module';
import {reducers} from './app.reducer';
import {ListingService} from './listing/listing-service';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FullDatePipe,
    ClickOutsideDirective,
    DirtyCheckComponent,
    LightboxComponent,
    DropzoneDirective,
    GcrSnackbarComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    A11yModule,
    MatFormFieldModule,
    MatInputModule,
    AuthModule,
    AppRoutingModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatRadioModule,
    AngularFireAuthModule,
    SharedMaterialModule,
    MatTooltipModule,
    GCRCounterInputModule,
    MatToolbarModule,
    GCRToggleSwitchModule,
    MatDatepickerModule,
    MatButtonModule,
    AngularFireFunctionsModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MessageStatusModule,
    MatSelectModule,
    MatCheckboxModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    BrowserAnimationsModule,
    OverlayModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatSnackBarModule,
    ConfirmationDialogModule,
    GCRBannerModule,
  ],

  entryComponents: [
    GcrSnackbarComponent,
    ConfirmationDialogComponent,
    LightboxComponent,
  ],

  providers: [
    UploaderService,
    UIService,
    ListingService,
    AuthService,
    { provide: REGION, useValue: 'us-central1' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
